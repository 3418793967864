export default {
  "plan.free": "Gratuit",
  "plan.basic": "Base",
  "plan.pro": "Pro",
  Free: "Gratuit",
  Basic: "Base",
  Pro: "Pro",
  "plan.questionsPerMonth": "résumés/questions par mois",
  "plan.unlimitedQuestions": "Résumés/questions illimités",
  "plan.daysChatHistory":
    "jours de conservation de l'historique des discussions",
  "plan.unlimitedChatHistory":
    "Conservation illimitée de l'historique des discussions",
  "plan.communitySupport": "Support de la communauté",
  "plan.emailSupport": "Support par courriel",
  "plan.perMonth": "/mois",
  "plan.managePlan": "Gérer le plan",
  "plan.upgrade": "Choisir ce plan",
  "nav.plan": "Plan",
  "nav.usage": "Utilisation",
  "nav.logout": "Déconnexion",
  "usage.planLimit":
    "Votre plan {plan} inclut {limit} résumés/questions chaque mois.",
  "usage.currentUsage": "Utilisation mensuelle actuelle",
  "usage.reset": "Votre utilisation sera réinitialisée le {date, date, long}.",
  "subscription.confirmation": "Confirmation d'abonnement",
  "subscription.thankYou": "Merci de vous être abonné à Docuprobe ! ❤️",
  "Sign in or Signup to continue to Docuprobe":
    "Connectez-vous ou inscrivez-vous pour continuer",
  "login.terms":
    'En vous connectant, vous acceptez nos <a href="https://docuprobe.app/terms" target="_blank">Conditions d\'utilisation</a>.',
  "Sign in with {{provider}}": "Se connecter avec {{provider}}",
  "Welcome to Docuprobe! 👋": "Bienvenue à Docuprobe! 👋",
  "You have successfully logged in and can now use our browser extension.":
    "Vous vous êtes connecté avec succès et pouvez désormais utiliser notre extension.",
  "The extension is already open in your browser. Just browse to a website with some text and start interacting with it using the extension.":
    "L'extension est déjà ouverte dans votre navigateur. Il vous suffit de naviguer sur un site web contenant du texte et de commencer à interagir avec celui-ci en utilisant l'extension.",
  "If you have any issues, please use the Feedback form in the extension.":
    "Si vous rencontrez des problèmes, veuillez utiliser le formulaire de contact dans l'extension.",
  "To use the extension, simply click on the extensions icon":
    "Pour utiliser l'extension, il vous suffit de cliquer sur l'icône des extensions",
  "in your browser toolbar, and select Docuprobe":
    "dans la barre d'outils de votre navigateur, et de sélectionner Docuprobe",
  "Don't forget to pin the extension to easily access it at any time.":
    "N'oubliez pas d'épingler l'extension pour y accéder facilement à tout moment.",
  "oups.notSupported":
    "Cette extension est uniquement disponible pour les navigateurs Chrome sur ordinateur de bureau. Nous pourrions un jour proposer quelque chose pour les appareils mobiles, mais pour l'instant, vous ne pouvez l'utiliser que sur votre ordinateur.",
  currencyUSD: "{val, number, ::compact-short currency/USD}",
  "Oups, something went horribly wrong!":
    "Oups, quelque chose s'est très mal passé !",
  "Click the button below to try again. If this issue persists, know that we have been notified and we will work on it.":
    "Cliquez sur le bouton ci-dessous pour réinitialiser l'extension et réessayer. Si le problème persiste, sachez que nous avons été informés et nous travaillerons dessus.",
  "Show error details": "Afficher les détails de l'erreur",
  // Add more translations here
};
