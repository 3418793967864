import * as Sentry from "@sentry/browser";
declare global {
  interface ErrorConstructor {
    captureStackTrace(targetObject: Object, constructorOpt?: Function): void;
  }
}
export class ApplicationError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = "ApplicationError";
    Error.captureStackTrace(this, this.constructor);
  }
}

export class Logger {
  private static isInitialized: boolean = false;
  //   constructor() {
  //     Logger.init();
  //   }
  private static init() {
    if (!Logger.isInitialized) {
      Sentry.init({
        dsn: "https://8942b7fc00b62014e6def269c139e844@o4506946193195008.ingest.us.sentry.io/4506946196799488",
      });
    }
    Logger.isInitialized = true;
  }

  public static log(message: string) {
    if (!Logger.isInitialized) {
      Logger.init();
    }
    Sentry.captureMessage(message);
  }
  public static warn(message: string) {
    if (!Logger.isInitialized) {
      Logger.init();
    }
    Sentry.captureMessage(message, "warning");
  }
  public static error(message: string | unknown) {
    if (!Logger.isInitialized) {
      Logger.init();
    }
    if (typeof message === "string") {
      Sentry.captureException(new ApplicationError(message));
    } else {
      Sentry.captureException(message);
    }
  }
}
