// import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import "./index.css";
import { AppProvider } from "./Provider/AppProvider.tsx";
import Analytics from "analytics";
import mixpanelPlugin from "@analytics/mixpanel";
import { AnalyticsProvider } from "use-analytics";
import * as Sentry from "@sentry/react";
import { ErrorPage } from "./ErrorPage.tsx";
import "./i18n";

const MIXPANEL_TOKEN = import.meta.env.VITE_MIXPANEL_TOKEN;
const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;
// mixpanel.init(MIXPANEL_TOKEN, {debug: true, track_pageview: true, persistence: 'localStorage'});

/* Initialize analytics & load plugins */
const analytics = Analytics({
  app: "probeai-webapp",
  plugins: [
    mixpanelPlugin({
      token: MIXPANEL_TOKEN,
    }),
  ],
});

Sentry.init({
  dsn: SENTRY_DSN,
  // debug: true,
  integrations: [
    // captureConsoleIntegration({ levels: ["error"] }),
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: [/^https:\/\/yourserver\.io\/api/],
  // // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const locale = Intl.DateTimeFormat().resolvedOptions().locale; // Get the user's locale
// const locale = navigator.language.split(/[-_]/)[0];
// const locale = "en";
console.log("locale", locale);

ReactDOM.createRoot(document.getElementById("root")!).render(
  // <React.StrictMode>
  <AnalyticsProvider instance={analytics}>
    <Sentry.ErrorBoundary fallback={ErrorPage}>
      <AppProvider>
        <App />
      </AppProvider>
    </Sentry.ErrorBoundary>
  </AnalyticsProvider>
  /* </React.StrictMode> */
);
