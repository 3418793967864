import { useEffect, createElement } from "react";
import { useAnalytics } from "use-analytics";
import { Container } from "@mui/material";
import { getAppContext } from "../Provider/AppProvider";

const PRICE_TABLE_ID = import.meta.env.VITE_PRICE_TABLE_ID;
const STRIPE_PUBLISHABLE_KEY = import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY;

const StripePricingTable = ({
  user,
}: {
  user: { id: string; email: string };
}) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.stripe.com/v3/pricing-table.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return createElement("stripe-pricing-table", {
    "pricing-table-id": PRICE_TABLE_ID,
    "publishable-key": STRIPE_PUBLISHABLE_KEY,
    "customer-email": user.email,
    "client-reference-id": user.id,
  });
};

export const Upgrade = () => {
  const { page } = useAnalytics();
  const { authInfo } = getAppContext();
  if (!authInfo.user) {
    throw new Error("User must be logged in to view this page");
  }

  useEffect(() => {
    page();
  }, []);

  return (
    <Container sx={{ ml: { sm: "0px", md: "200px" } }}>
      <StripePricingTable user={authInfo.user} />
    </Container>
  );
};
