import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import ICU from "i18next-icu";
import enMessages from "./locales/en";
import frMessages from "./locales/fr";

i18n
  .use(ICU)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: enMessages,
      },
      fr: {
        translation: frMessages,
      },
    },
    supportedLngs: ["en", "fr"],

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
