export default {
  "plan.free": "Free",
  "plan.basic": "Basic",
  "plan.pro": "Pro",
  Free: "Gratuit",
  Basic: "Base",
  Pro: "Pro",
  "plan.questionsPerMonth": "summaries/questions per month",
  "plan.unlimitedQuestions": "Unlimited summaries/questions",
  "plan.daysChatHistory": "days chat history retention",
  "plan.unlimitedChatHistory": "Unlimited chat history retention",
  "plan.communitySupport": "Community support",
  "plan.emailSupport": "Email support",
  "plan.perMonth": "/month",
  "plan.managePlan": "Manage plan",
  "plan.upgrade": "Upgrade",
  "nav.plan": "Plan",
  "nav.usage": "Usage",
  "nav.logout": "Logout",
  "usage.planLimit":
    "Your {plan} plan includes {limit} summaries/questions every month.",
  "usage.currentUsage": "Current monthly usage",
  "usage.reset": "Your usage will reset on {date, date, long}.",
  "subscription.confirmation": "Subscription confirmation",
  "subscription.thankYou": "Thank you for subscribing to Docuprobe! ❤️",
  "Sign in or Signup to continue to Docuprobe":
    "Sign in or Signup to continue to Docuprobe",
  "login.terms":
    'By signing in, you agree to our <a href="https://docuprobe.app/terms" target="_blank">Terms of Service</a>.',
  "Sign in with {provider}": "Sign in with {{provider}}",
  "Welcome to Docuprobe! 👋": "Welcome to Docuprobe! 👋",
  "You have successfully logged in and can now use our browser extension.":
    "You have successfully logged in and can now use our browser extension.",
  "The extension is already open in your browser. Just browse to a website with some text and start interacting with it using the extension.":
    "The extension is already open in your browser. Just browse to a website with some text and start interacting with it using the extension.",
  "If you have any issues, please use the Feedback form in the extension.":
    "If you have any issues, please use the Feedback form in the extension.",
  "To use the extension, simply click on the extensions icon":
    "To use the extension, simply click on the extensions icon",
  "in your browser toolbar, and select Docuprobe":
    "in your browser toolbar, and select Docuprobe",
  "Don't forget to pin the extension to easily access it at any time.":
    "Don't forget to pin the extension to easily access it at any time.",
  "oups.notSupported":
    "This extension is only available for Chrome browsers on desktop. We might one day have something for mobile device, but for now, you can only use it on your computer.",
  currencyUSD: "{val, number, ::compact-short currency/USD}",
  "Oups, something went horribly wrong!":
    "Oups, something went horribly wrong!",
  "Click the button below to try again. If this issue persists, know that we have been notified and we will work on it.":
    "Click the button below to try again. If this issue persists, know that we have been notified and we will work on it.",
  "Show error details": "Show error details",
  // Add more translations here
};
