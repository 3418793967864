import { useState, useEffect, createContext, useContext } from "react";
type AuthInfoType = {
  user: {
    id: string;
    email: string;
    plan: string;
  } | null;
  isAuthenticated: boolean;
};
export type AppContextType = {
  authInfo: AuthInfoType;
  setAuthInfo: React.Dispatch<React.SetStateAction<AuthInfoType>>;
  extensionOpen: boolean;
  setExtensionOpen: React.Dispatch<React.SetStateAction<boolean>>;
};
export const AppContext = createContext<AppContextType | null>(null);

export function getAppContext(): AppContextType {
  const authContext = useContext(AppContext);
  if (!authContext) {
    throw new Error("AppContext must be used within an AppProvider");
  }
  return authContext;
}

export const AppProvider = ({ children }: { children: React.ReactNode }) => {
  const [authInfo, setAuthInfo] = useState(() => {
    const saved = localStorage.getItem("authInfo");
    return saved ? JSON.parse(saved) : false;
  });
  const [extensionOpen, setExtensionOpen] = useState(false);

  useEffect(() => {
    localStorage.setItem("authInfo", JSON.stringify(authInfo));
  }, [authInfo]);

  return (
    <AppContext.Provider
      value={{ authInfo, setAuthInfo, extensionOpen, setExtensionOpen }}
    >
      {children}
    </AppContext.Provider>
  );
};
