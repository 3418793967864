import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  Typography,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";
import { t } from "i18next";

export const ErrorPage = ({
  error,
  resetError,
  componentStack,
}: {
  error: Error;
  resetError: () => void;
  componentStack: string;
}) => {
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      style={{ minHeight: "100vh" }}
    >
      <Box mb={4} display="flex" flexDirection="column" alignItems="center">
        <img
          src={
            chrome?.runtime?.getURL
              ? chrome.runtime.getURL("/broken.jpeg")
              : "/broken.jpeg"
          }
          alt="Something went wrong!"
          style={{ width: "300px", height: "300px" }}
        />
        <Typography variant="h6" align="center">
          {t("Oups, something went horribly wrong!")}
        </Typography>
        <Typography
          color="text.secondary"
          variant="body2"
          align="center"
          margin="20px"
          gutterBottom
        >
          {t(
            "Click the button below to try again. If this issue persists, know that we have been notified and we will work on it."
          )}
        </Typography>

        <Button variant="contained" onClick={resetError}>
          Reset
        </Button>
        <br />
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{t("Show error details")}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box display="flex" flexDirection="column" alignItems="left">
              <Typography
                color="text.secondary"
                variant="subtitle1"
                align="left"
              >
                Error: {error.message}
              </Typography>
              <pre>
                <code>
                  {componentStack
                    .split(" at")
                    .map((line: string, index: number) => (
                      <React.Fragment key={index}>
                        {" ".repeat(index)}
                        {line}
                      </React.Fragment>
                    ))}
                </code>
              </pre>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Grid>
  );
};
