import { createClient } from "@supabase/supabase-js";
import { Auth } from "@supabase/auth-ui-react";
import { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
  Navigate,
  Outlet,
  useLocation,
} from "react-router-dom";
import {
  Typography,
  Box,
  Grid,
  Container,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  IconButton,
  Button,
  Paper,
  useTheme,
  useMediaQuery,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Chip,
  LinearProgress,
  CircularProgress,
} from "@mui/material";
import { getAppContext } from "./Provider/AppProvider.tsx";
import {
  Logout as LogoutIcon,
  Subscriptions as SubscriptionsIcon,
  Equalizer as EqualizerIcon,
  // AccountCircle as AccountCircleIcon,
  Menu as MenuIcon,
} from "@mui/icons-material";
import { Link as RouterLink } from "react-router-dom";

import {
  // Import predefined theme
  ThemeSupa,
} from "@supabase/auth-ui-shared";
import { useAnalytics } from "use-analytics";
import { Upgrade } from "./Pages/Upgrade.tsx";
import { useBackendApi, SusbcriptionInfo, getBackendURL } from "./api.ts";
import { toTitleCase } from "./util.ts";
import { ApplicationError } from "./tools/logger.ts";
import { useTranslation } from "react-i18next";

const BACKEND_URL = getBackendURL();
const WEBAPP_URL = import.meta.env.VITE_WEBAPP_URL || window.location.origin;
const SUPABASE_URL = import.meta.env.VITE_SUPABASE_URL;
const SUPABASE_ANON_KEY = import.meta.env.VITE_SUPABASE_ANON_KEY;

const INSTALLATION_IDS = [
  "kefkahklpfgaekcbmpcapimkbkefgjdn",
  "inmfmdmjdkopgpjbhgdjebmpdpdlfhao",
  "kopdobacpokkfldboancjodaheokmpld",
  "bfbfkolfmmlldhekadkncdejhdhoelbh", // Official extension ID
];

const getAuthInfoFromResponse = async (response: Response) => {
  const responseJson = await response.json();
  if (response.status !== 200) {
    return {
      user: null,
      isAuthenticated: false,
    };
  } else {
    return {
      user: {
        id: responseJson.user.id,
        email: responseJson.user.email,
        plan: responseJson.profile.plan,
      },
      isAuthenticated: true,
    };
  }
};

const notifyExtensions = (subject: string, installationIds: string[]) => {
  if (!chrome || !chrome.runtime || !chrome.runtime.sendMessage) {
    return;
  }
  try {
    installationIds.forEach((id) => {
      chrome.runtime.sendMessage(id, { subject }, function (res) {
        if (!res) {
          console.log("No response from extension");
        } else {
          console.log(res.response);
        }
      });
    });
  } catch (error) {
    console.error(error);
  }
};

const AuthCallback = () => {
  const { authInfo, setAuthInfo } = getAppContext();
  const [error, setError] = useState<Error | null>(null);
  const { identify } = useAnalytics();
  const searchParams = new URLSearchParams(location.search);
  const redirectParam = searchParams.get("redirect");
  let navigateTo = "extension";

  if (redirectParam) {
    navigateTo = redirectParam;
  }
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuthentication = async () => {
      // Extract JWT from URL fragment
      const hashParams = new URLSearchParams(window.location.hash.substring(1));
      const access_token = hashParams.get("access_token");
      if (access_token) {
        var headers = new Headers();
        headers.append("Content-Type", "application/json");
        var requestOptions: RequestInit = {
          method: "POST",
          redirect: "follow",
          body: JSON.stringify({ access_token }),
          credentials: "include",
          headers,
        };
        // Send token to backend API
        const response = await fetch(`${BACKEND_URL}/auth`, requestOptions);
        setAuthInfo(await getAuthInfoFromResponse(response));

        if (response.status === 200) {
          identify(authInfo.user?.id!, { email: authInfo.user?.email });
          // TODO: Add official extension id when it gets published
          notifyExtensions("authenticationUpdate", INSTALLATION_IDS);
          navigate(`/${navigateTo}`);
        } else {
          setError(
            new ApplicationError(
              `Error while authenticating: ${response.status} status code received`
            )
          );
        }
      } else {
        setError(
          new ApplicationError(
            "Error while authenticating: missing access_token from URL"
          )
        );
      }
    };

    checkAuthentication();
  }, []);

  if (error) {
    throw error;
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <CircularProgress />
    </div>
  );
};

const Login = () => {
  const { authInfo, setAuthInfo } = getAppContext();
  const [isLoading, setIsLoading] = useState(true);
  const { identify } = useAnalytics();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const searchParams = new URLSearchParams(location.search);
  const redirectParam = searchParams.get("redirect");
  let navigateTo = "extension";

  if (redirectParam) {
    navigateTo = redirectParam;
  }

  useEffect(() => {
    const checkAuthentication = async () => {
      var requestOptions: RequestInit = {
        method: "GET",
        redirect: "follow",
        credentials: "include",
      };
      const response = await fetch(`${BACKEND_URL}/auth`, requestOptions);
      setAuthInfo(await getAuthInfoFromResponse(response));
      if (response.status === 200) {
        identify(authInfo.user?.id!, { email: authInfo.user?.email });
        navigate(`/${navigateTo}`);
      } else {
        setIsLoading(false);
      }
    };

    checkAuthentication();
  }, []);

  if (isLoading)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );

  const redirectTo = redirectParam
    ? `${WEBAPP_URL}/auth/callback?redirect=${redirectParam}`
    : `${WEBAPP_URL}/auth/callback`;

  return (
    <div id="authComponent">
      <Paper
        elevation={3}
        style={{
          padding: "40px",
          margin: "auto",
          maxWidth: "400px",
          textAlign: "center",
          marginTop: "100px",
        }}
      >
        <img
          src={"docuprobe-logo-light-small.png"}
          alt="Docuprobe"
          style={{ width: "200px" }}
        />
        <Typography variant="body1" color="text.secondary" pt="20px">
          {t("Sign in or Signup to continue to Docuprobe")}
        </Typography>
        <Auth
          supabaseClient={supabase}
          appearance={{ theme: ThemeSupa }}
          providers={["google", "facebook"]}
          onlyThirdPartyProviders={true}
          redirectTo={redirectTo}
          localization={{
            variables: {
              sign_in: {
                social_provider_text: t("Sign in with {{provider}}"),
              },
            },
          }}
        />
        <Typography
          variant="body2"
          color="text.secondary"
          style={{ marginTop: "20px" }}
        >
          <span
            dangerouslySetInnerHTML={{
              __html: t("login.terms"),
            }}
          />
        </Typography>
      </Paper>
    </div>
  );
};

const SubscriptionCallback = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { track } = useAnalytics();

  useEffect(() => {
    track("User subscribed");
  }, []);

  useEffect(() => {
    const updateUserProfile = async () => {
      console.log("Updating subscription...");
      const searchParams = new URLSearchParams(location.search);
      const checkout_session_id = searchParams.get("checkout_session_id");

      if (checkout_session_id) {
        var headers = new Headers();
        headers.append("Content-Type", "application/json");
        var requestOptions: RequestInit = {
          method: "POST",
          redirect: "follow",
          body: JSON.stringify({ checkoutSessionId: checkout_session_id }),
          credentials: "include",
          headers,
        };
        const response = await fetch(
          `${BACKEND_URL}/subscription`,
          requestOptions
        );
        if (response.status === 200) {
          console.log("Subscription updated!");
          notifyExtensions("subscriptionUpdate", INSTALLATION_IDS);
          navigate("/subscription/confirmation");
        } else {
          const responseJson = await response.json();
          throw new ApplicationError(
            `Error while updating subscription: ${JSON.stringify(
              responseJson.error
            )}`
          );
        }
      } else {
        throw new ApplicationError(`No checkout_session_id found in URL`);
      }
    };

    updateUserProfile();
  }, []);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <CircularProgress />
    </div>
  );
};

const Extension = () => {
  const { extensionOpen } = getAppContext();
  const { track } = useAnalytics();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  useEffect(() => {
    track("Successful login", { location: "Extension page" });
  }, []);

  let textExtensionNotOpened = (
    <>
      {t("To use the extension, simply click on the extensions icon")}{" "}
      <img src="./chrome-extensions-icon.png" height="20px" />{" "}
      {t("in your browser toolbar, and select Docuprobe")}{" "}
      <img src="./icons/logo-verticals-32.png" height="20px" />.{" "}
      {t("Don't forget to pin the extension to easily access it at any time.")}
    </>
  );

  if (isSmallScreen) {
    return (
      <ContentCenter>
        <Typography variant="h3" component="h1">
          Oups! 🤷‍♂ ️
        </Typography>
        <Typography variant="body1">{t("oups.notSupported")}</Typography>
      </ContentCenter>
    );
  }

  return (
    <ContentCenter>
      <Grid
        container
        direction="column"
        alignItems="center"
        style={{ minHeight: "100vh" }}
      >
        <Box mb={4} display="flex" flexDirection="column" alignItems="left">
          <Box mb={2}>
            <Typography variant="h3" component="h1">
              {t("Welcome to Docuprobe! 👋")}
            </Typography>
          </Box>
          <Typography variant="body1">
            {t(
              "You have successfully logged in and can now use our browser extension."
            )}
          </Typography>
          <Typography variant="body1">
            {extensionOpen
              ? t(
                  "The extension is already open in your browser. Just browse to a website with some text and start interacting with it using the extension."
                )
              : textExtensionNotOpened}
          </Typography>
          <Typography variant="body1">
            {t(
              "If you have any issues, please use the Feedback form in the extension."
            )}
          </Typography>
        </Box>
      </Grid>
    </ContentCenter>
  );
};

// const Navigation = () => (
//   <nav>
//     <Link to="/login">Login</Link>
//     <Link to="/dashboard">Dashboard</Link>
//     <Link to="/usage">Usage</Link>
//     <Link to="/upgrade">Upgrade</Link>
//     <Link to="/subscription">Subscription</Link>
//   </nav>
// );

// const supabase = createClient(
//   "https://wbxxrpescmirktvjhxjf.supabase.co",
//   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6IndieHhycGVzY21pcmt0dmpoeGpmIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDkxNzYxNjAsImV4cCI6MjAyNDc1MjE2MH0.krRxUhnx8o8mGAK0_RxorEZldmi9C0SWUP1f6D8hW7I"
// );

const supabase = createClient(
  // "http://localhost:54321",
  SUPABASE_URL,
  SUPABASE_ANON_KEY
);

const ProtectedRoute = ({ redirectPath = "/login", children = null }) => {
  const { authInfo } = getAppContext();
  if (!authInfo.isAuthenticated) {
    return <Navigate to={redirectPath} replace />;
  }
  return children ? children : <Outlet />;
};

const Navigation = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [isDrawerOpen, setIsDrawerOpen] = useState(!isSmallScreen);
  const { authInfo } = getAppContext();
  const { logout } = useBackendApi();
  const { t } = useTranslation();

  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleLogout = async () => {
    await logout();
  };

  if (!authInfo.isAuthenticated) return null;
  return (
    <>
      {isSmallScreen && (
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          style={{ position: "fixed", zIndex: 1 }}
        >
          <MenuIcon />
        </IconButton>
      )}
      <Drawer
        variant={isSmallScreen ? "temporary" : "permanent"}
        open={!isDrawerOpen}
        onClose={handleDrawerToggle}
        anchor="left"
      >
        <List
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <div style={{ flexGrow: 1 }}>
            <div style={{ padding: "20px" }}>
              <a href="/">
                <img
                  src={"docuprobe-logo-light-small.png"}
                  alt="Logo"
                  style={{ width: "150px" }}
                />
              </a>
            </div>
            <ListItemButton component={RouterLink} to="/plan">
              <ListItemIcon>
                <SubscriptionsIcon />
              </ListItemIcon>
              <ListItemText primary={t("nav.plan")} />
            </ListItemButton>
            <ListItemButton component={RouterLink} to="/usage">
              <ListItemIcon>
                <EqualizerIcon />
              </ListItemIcon>
              <ListItemText primary={t("nav.usage")} />
            </ListItemButton>
          </div>
          <div style={{ alignSelf: "flex-end", width: "100%" }}>
            {/* <ListItemButton component={RouterLink} to="/account">
              <ListItemIcon>
                <AccountCircleIcon />
              </ListItemIcon>
              <ListItemText primary="Account" />
            </ListItemButton> */}
            <ListItemButton onClick={handleLogout}>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary={t("nav.logout")} />
            </ListItemButton>
          </div>
        </List>
      </Drawer>
    </>
  );
};

const ContentCenter = ({ children }: { children: any }) => (
  <Container
    maxWidth="md"
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      pt: { xs: 4, sm: 5 },
      pb: { xs: 8, sm: 12 },
      ml: { xs: "10px", sm: "200px" },
    }}
  >
    {children}
  </Container>
);

interface Plan {
  name: string;
  price: string;
  upgrade: boolean;
  manage: boolean;
  features: string[];
}

const Plan = () => {
  const [isLoadingPortal, setIsLoadingPortal] = useState(false);
  const [currentPlan, setCurrentPlan] = useState<string | undefined>();
  const [plans, setPlans] = useState<Plan[]>([]);
  const { getSubscriptionInfo } = useBackendApi();
  const { t } = useTranslation();

  const getPlans = (currentPlan: string): Plan[] => {
    const plans = [
      {
        name: t("plan.free"),
        price: t("currencyUSD", {
          val: 0,
          minimumFractionDigits: 0,
        }),
        upgrade: false,
        manage: false,
        features: [
          `30 ${t("plan.questionsPerMonth")}`,
          `7 ${t("plan.daysChatHistory")}`,
          t("plan.communitySupport"),
        ],
      },
      {
        name: t("plan.basic"),
        price: t("currencyUSD", {
          val: 5,
          minimumFractionDigits: 0,
        }),
        upgrade: currentPlan === "Free",
        manage: currentPlan === "Basic",
        features: [
          `1000 ${t("plan.questionsPerMonth")}`,
          `90 ${t("plan.daysChatHistory")}`,
          t("plan.emailSupport"),
        ],
      },
      {
        name: t("plan.pro"),
        price: t("currencyUSD", { val: 15, minimumFractionDigits: 0 }),
        upgrade: currentPlan === "Basic" || currentPlan === "Free",
        manage: currentPlan === "Pro",
        features: [
          t("plan.unlimitedQuestions"),
          t("plan.unlimitedChatHistory"),
          t("plan.emailSupport"),
        ],
      },
    ];
    console.log(plans);
    return plans;
  };

  useEffect(() => {
    const fetchSubInfo = async () => {
      const subInfo = await getSubscriptionInfo();
      if (!subInfo) {
        return;
      }
      console.log("Current plan: ", subInfo.plan);
      setCurrentPlan(subInfo.plan);
      setPlans(getPlans(subInfo.plan));
    };
    fetchSubInfo();
  }, []);

  const handleManagePlan = async () => {
    setIsLoadingPortal(true);
    var requestOptions: RequestInit = {
      method: "GET",
      redirect: "follow",
      credentials: "include",
    };
    const response = await fetch(
      `${BACKEND_URL}/subscription/portalUrl?return_url=${WEBAPP_URL}/plan`,
      requestOptions
    );
    if (response.status !== 200) {
      throw new ApplicationError("Error while fetching portal URL");
    }
    const responseData = await response.json();
    window.location.href = responseData.url;
  };

  if (isLoadingPortal || !currentPlan) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <ContentCenter>
      <Typography variant="h4" component="h1" gutterBottom>
        Plan
      </Typography>
      <Grid container spacing={3}>
        {plans.map((plan) => (
          <Grid item xs={12} sm={6} md={4} key={plan.name}>
            <Box
              border={currentPlan === plan.name ? 2 : 0}
              borderColor="primary.main"
            >
              <Card style={{ position: "relative", minHeight: "320px" }}>
                <CardHeader title={toTitleCase(plan.name)} />
                <CardContent style={{ minHeight: "170px" }}>
                  <Box display="flex" alignItems="baseline">
                    <Typography variant="h5">{plan.price}</Typography>
                    <Typography color="text.secondary">
                      {t("plan.perMonth")}
                    </Typography>
                  </Box>
                  <Box>
                    <ul>
                      {plan.features.map((feature, index) => (
                        <li key={index}>
                          <Typography
                            key={index}
                            variant="body2"
                            color="text.secondary"
                          >
                            {feature}
                          </Typography>
                        </li>
                      ))}
                    </ul>
                  </Box>
                </CardContent>
                <CardActions>
                  {currentPlan === plan.name && (
                    <Chip
                      label="Current Plan"
                      color="primary"
                      style={{ position: "absolute", top: 15, right: 15 }}
                    />
                  )}
                  {currentPlan === "Free" && plan.upgrade && (
                    <Button href="/upgrade">{t("plan.upgrade")}</Button>
                  )}
                  {currentPlan !== "Free" && plan.upgrade && (
                    <Button onClick={handleManagePlan}>
                      {t("plan.upgrade")}
                    </Button>
                  )}
                  {plan.manage && (
                    <Button onClick={handleManagePlan}>
                      {t("plan.managePlan")}
                    </Button>
                  )}
                </CardActions>
              </Card>
            </Box>
          </Grid>
        ))}
      </Grid>
    </ContentCenter>
  );
};

const Usage = () => {
  let progress = 0;
  const [subscriptionInfo, setSubscriptionInfo] = useState<
    SusbcriptionInfo | undefined
  >();
  if (subscriptionInfo?.currentUsage && subscriptionInfo?.usageLimit) {
    progress =
      (subscriptionInfo.currentUsage / subscriptionInfo.usageLimit) * 100;
  }
  const { getSubscriptionInfo } = useBackendApi();
  const { t } = useTranslation();

  useEffect(() => {
    // Fetch usage data
    const fetchSubInfo = async () => {
      const subInfo = await getSubscriptionInfo();
      if (!subInfo) {
        return;
      }
      setSubscriptionInfo(subInfo);
    };
    fetchSubInfo();
  }, []);

  if (!subscriptionInfo) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <ContentCenter>
      <Grid
        container
        direction="column"
        alignItems="flex-start"
        style={{ minHeight: "100vh" }}
      >
        <Typography variant="h4" component="h1" gutterBottom>
          {t("nav.usage")}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {t("usage.planLimit", {
            plan: t(subscriptionInfo.plan),
            limit: subscriptionInfo.usageLimit,
          })}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {t("usage.currentUsage")}: {subscriptionInfo.currentUsage}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {t("usage.reset", { date: new Date(subscriptionInfo.periodEnd) })}
        </Typography>
        <Box sx={{ width: { xs: "100%", sm: "50%" } }} mt="30px">
          <LinearProgress
            variant="determinate"
            value={progress}
            sx={{ height: "20px" }}
          />
        </Box>
      </Grid>
    </ContentCenter>
  );
};

const Account = () => {
  return (
    <ContentCenter>
      <Grid
        container
        direction="column"
        alignItems="flex-start"
        style={{ minHeight: "100vh" }}
      >
        <Typography variant="h4" component="h1" gutterBottom>
          Account
        </Typography>
        <Typography variant="body1" gutterBottom>
          This is the Account page.
        </Typography>
      </Grid>
    </ContentCenter>
  );
};

const SubscriptionConfirmation = () => {
  const { t } = useTranslation();
  return (
    <ContentCenter>
      <Grid
        container
        direction="column"
        alignItems="flex-start"
        style={{ minHeight: "100vh" }}
      >
        <Typography variant="h4" component="h1" gutterBottom>
          {t("subscription.confirmation")}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {t("subscription.thankYou")}
        </Typography>
      </Grid>
    </ContentCenter>
  );
};

const App = () => {
  return (
    <Router>
      <Navigation />
      <Routes>
        <Route index element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route element={<ProtectedRoute />}>
          {/* <Route path="/dashboard" element={<Dashboard />} />*/}
          <Route path="/usage" element={<Usage />} />
          <Route path="/plan" element={<Plan />} />
          <Route path="/account" element={<Account />} />
          <Route path="/upgrade" element={<Upgrade />} />
          {/* <Route path="/subscription" element={<Subscription />} /> */}
          <Route path="/extension" element={<Extension />} />
          <Route
            path="/subscription/callback"
            element={<SubscriptionCallback />}
          />
          <Route
            path="/subscription/confirmation"
            element={<SubscriptionConfirmation />}
          />
        </Route>
        <Route path="/auth/callback" element={<AuthCallback />} />
        <Route path="*" element={<Login />} />
      </Routes>
    </Router>
  );
};

export default App;
