import { getAppContext } from "./Provider/AppProvider";

export type SusbcriptionInfo = {
  plan: string;
  periodEnd: string;
  usageLimit: number;
  currentUsage: number;
};

export const getBackendURL = (): string => {
  if (window.location.origin === "https://portal.docuprobe.app") {
    return "https://api.docuprobe.app";
  }
  return import.meta.env.VITE_BACKEND_URL;
};
const BACKEND_URL = getBackendURL();

export const useBackendApi = () => {
  const { setAuthInfo } = getAppContext();

  const isAuthenticated = (response: Response): boolean => {
    if (response.status === 401) {
      setAuthInfo({ isAuthenticated: false, user: null });
      return false;
    }
    return true;
  };
  const logout = async (): Promise<void> => {
    await fetch(`${BACKEND_URL}/logout`, {
      credentials: "include",
    });
    setAuthInfo({ isAuthenticated: false, user: null });
  };

  const getSubscriptionInfo = async (): Promise<
    SusbcriptionInfo | undefined
  > => {
    const response = await fetch(`${BACKEND_URL}/subscription`, {
      credentials: "include",
    });
    const responseJson = await response.json();
    if (isAuthenticated(response)) {
      return responseJson;
    }
  };
  return { isAuthenticated, logout, getSubscriptionInfo };
};
// export const getUsage = async (): Promise<UsageData> => {
//   const response = await fetch(`${BACKEND_URL}/usage`, {
//     credentials: "include",
//   });
//   return response.json();
// };
